import Swal from 'sweetalert2';
import Spinner from "../components/general/Spinner";
import React, { useState, useEffect } from "react";
import {
	Footer,
} from "../components/dashboard";
import { useParams } from "react-router-dom";
import PackageServices from "../services/PackageServices";
import { ProceedSVG, RightArrowSVG } from "../assets/svg";
import { COLORS } from "../assets/Theme";
import PaymentServices from '../services/PaymentService';
import { toast } from "react-toastify";
import withReactContent from 'sweetalert2-react-content';

const CollectCashPayment = () => {
	const params = useParams();
	const [package_collection, setPackage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paymentaction, setPaymentAction] = useState(true);
	const [mpesaprompt, setMpesaPrompt] = useState(false);
	const [mpesa_number, setMpesaNumber] = useState('');
	const [paytill, setPayTill] = useState(false);
	const [transaction_code, setTransactionCode] = useState('');

	// increase size of mpesa number input

	useEffect(() => {
		fetchPackageDetails();
	}, []);

	const MySwal = withReactContent(Swal);

	function fetchPackageDetails() {
		setLoading(true);
		PackageServices.getPackageDetails(params.type, params.receipt).then(
			res => {
				setPackage(res?.package);
				setLoading(false);
			}
		).catch(error => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});
	}

	function PromptPayment() {
		if (!mpesa_number) {
			toast.error(`Please enter phone number`, {
				position: "bottom-center",
			});
			return;
		}

		setLoading(true);
		PaymentServices.makePackagePayment(
			{
				packages: [{ type: params?.type, id: package_collection?.id }],
				payment_phone_number: mpesa_number.replaceAll(" ", ""),
				pay_on_delivery: true,
				type: params?.type,
				business_id: package_collection?.businessId_id,
				payment_amount: parseInt(package_collection?.on_delivery_balance),
				created: package_collection?.createdBy_id,
			}
		).then(
			res => {
				setLoading(false);
				MySwal.fire({
					title: <p> Payment Processed Successfully </p>,
					didOpen: () => {
						// `MySwal` is a subclass of `Swal` with all the same instance & static methods
						// MySwal.showLoading();
					},
				});
				setPackage();
				fetchPackageDetails();
			}
		).catch(error => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});
	}


	function verifyPayment() {
		if (!transaction_code) {
			return;
		}

		PaymentServices.verifyPayment(
			{
				type: '',
				payment_phone_number: '',
				payment_amount: package_collection?.on_delivery_balance,
				packages: [{ "type": params?.type, "id": package_collection?.id }],
				business_id: package_collection?.businesses?.id,
				toPayAmount: params?.payment_amount,
				qid: transaction_code.replaceAll(" ", ""),
				created: package_collection?.createdBy_id,
			}
		).then(res => {
			MySwal.fire({
				title: <p> Payment Processed Successfully </p>,
				didOpen: () => {
					// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// MySwal.showLoading();
				},
			});
			setPackage();
			fetchPackageDetails();
		}).catch((error) => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
		});
	}

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className="main flex-grow ">
				{
					loading ?
						<Spinner />
						:
						<div>
							{
								/* if package exists */
								package_collection.id ?
									<div>
										<h5
											className={
												"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
											}
										>
											Package Payment
										</h5>

										{/* package details */}
										<div className="flex flex-col justify-center items-center bg-gray-100 mt-12 mx-12 mb-12 w-auto">
											<p className="flex justify-center text-bold mt-3 mb-3 font-bold text-xl">
												Package details
											</p>
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Receipt No:
												</p>
												<p className="w-44">
													{package_collection?.receipt_no}
												</p>
											</div>

											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Customer Name
												</p>
												<p className="w-44">
													{package_collection?.customerName}
												</p>
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Phone number
												</p>
												<p className="w-44">
													{package_collection?.customerPhoneNumber}
												</p>
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Sender
												</p>

												<p className="w-44">
													{package_collection?.businesses?.name}
												</p>
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Type:
												</p>

												<p className="w-44">
													{package_collection?.type}
												</p>
											</div>

											{/* delivery fee status. */}
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Delivery fee status
												</p>

												{
													package_collection?.payment_option === 'customer' ?
														<div>
															{
																package_collection?.payment_status === 'to-be-paid' ?
																	<p className="w-44">
																		Not Paid
																	</p>
																	:
																	<p className="w-44">
																		Paid
																	</p>
															}
														</div>
														:
														<p className="w-44">
															Paid
														</p>
												}
											</div>

											{/* delivery fee */}
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Delivery fee
												</p>

												{
													package_collection?.payment_option === 'customer' ?
														<p className="w-44">
															{package_collection?.delivery_fee}
														</p>
														:
													<p className="w-44">
													{package_collection?.delivery_fee}
														</p>
												}

											</div>

											{/* package value */}
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Package value
												</p>
												<p className="w-44">
													{package_collection?.package_value
													}
												</p>
											</div>


											{/* package fee status */}
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Package fee status
												</p>
												<p className="w-44">
													{package_collection?.payment_status === 'paid_collect' || package_collection?.payment_status === 'enterprise_paid_collect' || package_collection?.payment_option === 'customer' ?
														<p>
															Paid
														</p>
														: <p> Unpaid </p>
													}
												</p>
											</div>


											{/* Amount user will be prompted to pay */}
											{
												package_collection?.payment_status === 'paid' || package_collection?.payment_status === 'enterprise_pending' ?
													<div className="flex justify-around mb-5 bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
														<p className="w-32 font-bold">
															Amount to Pay
														</p>

														{
															package_collection?.payment_option === 'customer' ?
																<div className="w-44">
																	{package_collection?.payment_status === 'to-be-paid' ?
																		<p>
																			{package_collection?.delivery_fee}
																		</p>
																		:
																		<p>
																			{0}
																		</p>
																	}
																</div>
																:
																<div className="w-44">
																	{
																		/* enterprise packages unpaid */
																		package_collection?.payment_status === 'enterprise_pending' ?
																			<p>
																				{package_collection?.on_delivery_balance}
																			</p>
																			:
																			/* enterprise package paid */
																			package_collection?.payment_status === 'enterprise_collect' ?
																				<p>
																					0
																				</p>
																				:
																				<div>
																					{
																						/* rent shelf and collect my cash. no delivery fee consideration */
																						params.type === 'rent' ?
																							<p className="w-44">
																								{package_collection?.on_delivery_balance}
																							</p>
																							:
																							/* agent package that is collect my cash. only take the package value */
																							params.type === 'agent' ?
																								<p className="w-44">
																									{package_collection?.on_delivery_balance}
																								</p>
																								:
																								/* doorstep package that is collect cash. */
																								params.type === 'doorstep' ?
																									<p className="w-44">
																										{package_collection?.on_delivery_balance}
																									</p>
																									:
																									<p>
																									</p>
																					}
																				</div>
																	}
																</div>

														}
													</div>
													:
													<p>
													</p>
											}
										</div>										

										{
											paymentaction && package_collection?.payment_status === 'paid' || package_collection?.payment_status === 'enterprise_pending' || package_collection?.payment_status === 'to-be-paid' ?
												<div className="flex flex-col justify-center items-center mt-4 mb-12">
													<ProceedSVG color={COLORS.grey_500} height="40px" width="40px" />

													<button
														className={`flex-0 w-56 mb-6 mt-4 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg ${loading && "opacity-70 cursor-no-drop"
															}`}
														type={"submit"}
														disabled={loading}
														onClick={() => { setMpesaPrompt(!mpesaprompt); setPayTill(false); }}
													>
														{loading ? <Spinner /> : "Prompt Payment"}
													</button>

													{
														mpesaprompt ?
															<div
																className={
																	"flex w-42 bg-white h-12 lg:h-16 items-center border border-grey_300 rounded-lg pl-3.5 overflow-hidden"}
															>

																<input
																	className={
																		"flex w-40 h-full text-base font-[400] focus:outline-none text-grey_500"
																	}
																	placeholder={"Enter mpesa number"}
																	value={mpesa_number}
																	onChange={(e) => setMpesaNumber(e.target.value)}
																/>

																<button
																	className={
																		"h-full px-3 bg-grey_50 flex items-center justify-center outline-none"
																	}
																	onClick={PromptPayment}
																>
																	<RightArrowSVG color={"#667085"} />
																</button>

															</div>

															:
															<div>
															</div>
													}


													<button
														className={`flex-0 w-56 mt-4 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg ${loading && "opacity-70 cursor-no-drop"
															}`}
														type={"submit"}
														disabled={loading}
														onClick={() => { setMpesaPrompt(false); setPayTill(!paytill) }}
													>
														{loading ? <Spinner /> : "Manual Payment"}
													</button>


													{
														paytill ?
															<div className="flex flex-col items-center justify-center mt-6 mb-6">
																{/* show steps to pay then button to input transaction code */}
																<ol>
																	<li className="list-decimal mx-12">
																		Go to your M-pesa toolkit and select <span className="font-bold"> Lipa na M-Pesa </span>
																	</li>

																	<li className="list-decimal mx-12">
																		Select <span className="font-bold"> Buy Goods and Services </span>
																	</li>

																	<li className="list-decimal mx-12">
																		You'll be prompted to enter <span className="font-bold"> till number </span>
																	</li>

																	<li className="list-decimal mx-12">
																		Enter <span className="font-bold"> 5684653 </span> as the till number and pay
																	</li>

																	<li className="list-decimal mx-12">
																		Wait for the M-Pesa confirmation SMS
																	</li>

																	<li className="list-decimal mx-12">
																		Copy paste the transaction code (i.e RBM...BV4) <span className="font-bold"> below </span>
																	</li>

																	<li className="list-decimal mx-12">
																		Enter <span className="font-bold"> transaction code </span> below
																	</li>

																</ol>

																{/* input transaction code */}
																<div
																	className={
																		"flex mt-6 bg-white h-full items-center justify-center border border-grey_300 rounded-lg overflow-hidden"}
																>

																	<input
																		className={
																			"w-56 h-12 lg:h-16 text-base font-[400] focus:outline-none text-grey_500"
																		}
																		placeholder={"Enter transaction code"}
																		value={transaction_code}
																		onChange={(e) => setTransactionCode(e.target.value)}
																	/>

																	<button
																		className={
																			"h-full px-3 bg-grey_50 flex items-center justify-center outline-none"
																		}
																		onClick={verifyPayment}
																	>
																		<RightArrowSVG color={"#667085"} />
																	</button>

																</div>

															</div>
															:
															<div>

															</div>
													}


												</div>
												:
												<div>

												</div>
										}


									</div>
									:
									<h5
										className={
											"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
										}
									>
										Package Not Available
									</h5>
							}
						</div>
				}
				<div />
			</div>
			<Footer />
		</div >
	);

};

export default CollectCashPayment;
