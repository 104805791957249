import axios from "axios";
import { BASE_URL } from "../Config";

const fetchSecurityQuestion = async (phone) => {
	const response = await axios.get(
    `${BASE_URL}/withdrawals/wallet-question/${phone}`
  );
	
  return response?.data;
};

const answerWalletQuestion = async (phone,answer) => {
	const response = await axios.get(
    `${BASE_URL}/withdrawals/wallet-answer/${phone}/${answer}`
  );
	
  return response?.data;
};

const resetWalletPin = async (data) => {
	const response = await axios.put(`${BASE_URL}/withdrawals/wallet-pin-reset`, data);
	
  return response?.data;
};

const WalletServices = {
	fetchSecurityQuestion,
	answerWalletQuestion,
	resetWalletPin,
};

export default WalletServices;
