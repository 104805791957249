import React, { useState, useEffect } from "react";
import {
	Footer,
} from "../components/dashboard";
import WalletServices from "../services/WalletServices.js";
import CustomerCareServices from "../services/CustomerCareServices.js";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Spinner from "../components/general/Spinner";
import { toast } from "react-toastify";

const WalletReset = () => {
	const [phone, setPhone] = useState('');
	const [security_question, setSecurityQuestion] = useState('');
	const [num_submitted, setNumSubmitted] = useState(false);
	const [security_answer, setSecurityAnswer] = useState('');
	const [ans_submitted, setAnsSubmitted] = useState(false);
	const [answered, setAnswered] = useState(false);
	const [newpin, setNewPin] = useState('');
	const [pin_submitted, setPinSubmitted] = useState(false);
	const [success_reset, setSuccessReset] = useState(false);
	const [loading, setLoading] = useState(false);
	const [customer_care, setCustomerCare] = useState('');

	const MySwal = withReactContent(Swal);

	function getCustomerCareNumber () {
		CustomerCareServices.fetchCustomerCareNum().then((res) => {
			console.log(res);
			setCustomerCare(res?.number);
		}).catch(error => {
			console.log('Could not get customer care number');
		});
	};
	
	useEffect(() =>{
		getCustomerCareNumber();
	},[]);
	
	function getWalletSecQuestion(e) {
		e.preventDefault();
		setNumSubmitted(true);
		if (!phone) {
			console.log('Please enter phone number');
			toast.error(`Please enter phone number`, {
				position: "bottom-center",
			});
			setNumSubmitted(false);
			return;
		}

		setLoading(true);
		WalletServices.fetchSecurityQuestion(phone).then(res => {
			console.log(res);
			if (res.question) {
				setSecurityQuestion(res.question);
			}
			setLoading(false);
		}).catch(error => {
			setLoading(false);
			setNumSubmitted(false);
			if (error.response.data?.message === 'No such user found') {
				toast.error(`No such user found`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'User does not have a wallet pin') {
				console.log('You do not have a wallet pin');
				toast.error(`You do not have a wallet pin`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'Wrong Phone Number Format') {
				console.log('The number you provided is incorrect');
				toast.error(`The number you provided is incorrect`, {
					position: "bottom-center",
				});
				return;
			}

			else {
				console.log(error.message);
				toast.error(error.message, {
					position: "bottom-center",
				});
				return;
			}

		});

		// if error set num_submitted to false
	}

	function sendWalletSecurityQuestion(e) {
		setAnsSubmitted(true);
		e.preventDefault();
		if (!security_answer) {
			setAnsSubmitted(false);
			console.log('Please enter security answer');
			toast.error(`Please enter security answer`, {
				position: "bottom-center",
			});
			return;
		}

		setLoading(true);
		WalletServices.answerWalletQuestion(phone, security_answer).then(res => {
			setLoading(false);
			console.log(res);
			setAnswered(true);
		}).catch(error => {
			setLoading(false);
			setAnsSubmitted(false);
			if (error.response.data?.message === 'No such user found') {
				console.log('No such user found');
				toast.error(`No such user found`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'User does not have a wallet pin') {
				console.log('You do not have a wallet pin');
				toast.error(`User does not have a wallet pin`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'Wrong Phone Number Format') {
				console.log('The number you provided is incorrect');
				toast.error(`The number you provided is incorrect`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'Wrong answer!') {
				console.log('Wrong answer provided');
				toast.error(`Wrong answer provided`, {
					position: "bottom-center",
				});
				return;
			}

			else {
				console.log(error.message);
				toast.error(error.message, {
					position: "bottom-center",
				});
			}
		});
	}

	function sendWalletPinResetRequest(e) {
		e.preventDefault();
		if (!parseInt(newpin)) {
			setPinSubmitted(false);
			console.log('Please provide a pin with four numbers');
			toast.error(`Please provide a pin with four numbers`, {
				position: "bottom-center",
			});
			return;
		}

		if (newpin.length !== 4) {
			setPinSubmitted(false);
			console.log('Please provide a pin with four digits');
			toast.error(`Please provide a pin with four digits`, {
				position: "bottom-center",
			});
			return;
		}
		
		
		setLoading(true);
		setPinSubmitted(true);
		WalletServices.resetWalletPin({ phone, "pin": newpin }).then(res => {
			setLoading(false);
			console.log(res);
			setSuccessReset(true);
			MySwal.fire({
				title: <p>Pin has been reset Successfully </p>,
				didOpen: () => {
					// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// MySwal.showLoading();
				},
			}).then(() => {
				setSuccessReset(false);
				setAnsSubmitted(false);
				setAnswered(false);
				setNewPin('');
				setNumSubmitted(false);
				setPinSubmitted(false);
				setPhone('');
				setSecurityAnswer('');
				setSecurityQuestion('');
			});
		}).catch(error => {
			setLoading(false);
			setPinSubmitted(false);
			if (error.response.data?.message === 'No such user found') {
				console.log('No such user found');
				toast.error(`No such user found`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'User does not have a wallet pin') {
				console.log('You do not have a wallet pin');
				toast.error(`You do not have a wallet pin`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'Wrong Phone Number Format') {
				console.log('The number you provided is incorrect');
				toast.error(`The number you provided is incorrect`, {
					position: "bottom-center",
				});
				return;
			}

			if (error.response.data?.message === 'Wrong pin format!') {
				console.log('Wrong pin format');
				toast.error(`Wrong pin format`, {
					position: "bottom-center",
				});
				return;
			}

			else {
				toast.error(error.message, {
					position: "bottom-center",
				});
				console.log(error.message);
			}
		});
	};

	function cannotRemember() {
		setSecurityQuestion('');
		MySwal.fire({
			title: <p> Please Contact Customer Care ({customer_care}) To Continue </p>,
			didOpen: () => {
				// `MySwal` is a subclass of `Swal` with all the same instance & static methods
				// MySwal.showLoading();
			},
		}).then(() => {
			setSuccessReset(false);
			setAnsSubmitted(false);
			setAnswered(false);
			setNewPin('');
			setNumSubmitted(false);
			setPinSubmitted(false);
			setPhone('');
			setSecurityAnswer('');
			setSecurityQuestion('');
		});
	}


	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className={"main flex-grow "}>
				{success_reset ? <div> </div> :
					<div>
						{loading ?
							<Spinner />
							:
							<div>
								<h5
									className={
										"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
									}
								>
									Reset Your Pochi Pin
								</h5>
								<div>
									<form className="flex flex-col items-center justify-center mt-6 mb-12" onSubmit={getWalletSecQuestion}>
										<div className="mb-6">
											<InputItem
												required
												label={"Phone number"}
												placeHolder={"Phone"}
												name={"first"}
												value={phone}
						 onChange={(e) => setPhone(e.target.value.trim())}
											/>

										</div>

										{num_submitted ?
											<div></div>
											:
											<button
												className={`flex-1 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg`}
												type={"submit"}
											>
												Submit
											</button>
										}
									</form>
								</div>

								{
									security_question ?
										<div>
											<form className="flex flex-col items-center justify-center mt-6 mb-12" onSubmit={sendWalletSecurityQuestion}>
												<div className="mb-6">

													<InputItem
														required
														label={security_question}
														placeHolder={""}
														name={"first"}
														value={security_answer}
														onChange={(e) => setSecurityAnswer(e.target.value)}
													/>

													{
														ans_submitted ? <div> </div> :
															<button className="text-green-500 mt-4" onClick={cannotRemember}> I cannot remember the answer  </button>
													}
												</div>

												{ans_submitted ?
													<div></div>
													:
													<button
														className={`flex-1 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg`}
														type={"submit"}
													>
														Submit
													</button>
												}
											</form>
										</div>
										:
										<div>

										</div>
								}


								{
									answered ?
										<div>
											<form className="flex flex-col items-center justify-center mt-6 mb-12" onSubmit={sendWalletPinResetRequest}>
												<div className="mb-6">
													<InputItem
														required
														label={"Enter Your New Pin"}
														placeHolder={""}
														name={"first"}
														value={newpin}
														onChange={(e) => setNewPin(e.target.value)}
													/>
												</div>

												{pin_submitted ?
													<div>
													</div>
													:
													<button
														className={`flex-1 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg`}
														type={"submit"}
													>
														Submit
													</button>
												}
											</form>
										</div>
										:
										<div>

										</div>
								}
							</div>
						}
					</div>
				}
			</div>

			<Footer />
		</div>
	);
};

export default WalletReset;


const InputItem = ({ label, placeHolder, ...rest }) => (
	<div className={" md:mr-0 flex-1 "}>
		<label className={"text-sm font-inter font-[500] text-grey_700 "} for="">
			{label}
		</label>
		<br />
		<input
			className={
				"bg-white rounded-[8px] border border-grey_300 mt-2 w-full px-4 py-2.5 font-inter text-sm md:text-base"
			}
			placeholder={placeHolder}
			{...rest}
		/>
	</div>
);
