import React, { useState } from "react";
import {
  FacebookSvg,
  InstagramSvg,
  MailSvg,
  MessageSvg,
  PhoneSvg,
  ThreadsSvg,
  TwitterSvg,
} from "../../assets/svg";
import { toast } from "react-toastify";
import ContactServices from "../../services/ContactServices";

const Contact = () => {
  const [state, setState] = useState({
    first: "",
    last: "",
    phone: "",
    email: "",
    message: "",
    verified: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  async function sendMessage(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await ContactServices.sendEmail({
        name: state.first + " " + state.last,
        phone: state.phone,
        email: state.email,
        message: state.message,
      });
      setLoading(false);
      toast.success(`Email sent.`, {
        position: "bottom-center",
      });
      setState({
        first: "",
        last: "",
        phone: "",
        email: "",
        message: "",
        verified: false,
      });
    } catch (e) {
      toast.error(`Email not sent. ${e.response.data?.message || e.message}`, {
        position: "bottom-center",
      });

      setLoading(false);
    }
  }

  return (
    <div
      className={
        "bg-[#F8F8F8] rounded-t-[78px] px-4 md:p-12 mt-8 md:mt-20 md:pb-48"
      }
    >
      <form className={"xl:mx-28"} onSubmit={sendMessage}>
        <h1
          className={
            "font-times text-[32px] md:text-[48px] text-center font-[600] md:text-left text-black leading-[52px] "
          }
        >
          Contact Us
        </h1>

        <div
          className={
            "flex flex-col md:flex-row py-3 md:py-8 md:gap-x-7 pr-2 items-start "
          }
        >
          <div className={"flex flex-col w-full gap-y-4 md:w-1/2 pt-1 "}>
            <div
              className={
                "flex flex-col md:flex-row md:gap-x-4 gap-y-4 md:gap-y-0"
              }
            >
              <InputItem
                required
                label={"First name"}
                placeHolder={"First name"}
                name={"first"}
                value={state?.first}
                onChange={handleChange}
              />
              <InputItem
                label={"Last name"}
                name={"last"}
                value={state?.last}
                onChange={handleChange}
                placeHolder={"Last name"}
              />
            </div>

            <InputItem
              required
              label={"Email"}
              placeHolder={"you@company.com"}
              type={"email"}
              name={"email"}
              value={state?.email}
              onChange={handleChange}
            />

            <InputItem
              required
              label={"Phone number"}
              placeHolder={"+254 712 345678"}
              name={"phone"}
              value={state?.phone}
              onChange={handleChange}
            />

            <div className={"hidden md:block"}>
              <h6
                className={
                  "font-[700] text-base md:text-[21px] font-lato text-grey_700"
                }
              >
                Or send us a DM directly
              </h6>

              <div className={"flex gap-1 md:gap-x-2 mt-2 mb-2 md:mb-0"}>
                {socials?.map(({ icon, link, name }) => (
                  <SocialBtn icon={icon} name={name} link={link} />
                ))}
              </div>
            </div>
          </div>

          <div
            className={"flex flex-col gap-y-4 w-full md:w-1/2 mt-2 md:mt-0 "}
          >
            <div className={"text-sm font-inter font-[500] text-grey_700 "}>
              Message
            </div>

            <textarea
              required
              className={
                "h-56 rounded-lg bg-white p-2 border border-grey_300 w-full"
              }
              name={"message"}
              onChange={handleChange}
              value={state?.message}
            />

            <div
              className={
                " font-inter font-[400] text-base text-grey_500 flex items-center gap-x-2  "
              }
            >
              <input
                type={"checkbox"}
                required
                className={
                  "h-5 w-5 bg-white border border-grey_300 outline-none rounded overflow-hidden"
                }
              />

              <p className={"font-inter text-sm md:text-base"}>
                {" "}
                Your agree to our friendly{" "}
                <a
                  href={"https://pickupmtaani.com/terms.html"}
                  className={"underline cursor-pointer text-black"}
                >
                  privacy policy
                </a>
              </p>
            </div>

            <button
              className={`flex-1 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg ${
                loading && "opacity-70 cursor-no-drop"
              }`}
              type={"submit"}
              disabled={loading}
            >
              {loading ? <Loader /> : "Send message"}
            </button>
          </div>

          <div className={"md:hidden mt-2"}>
            <h6
              className={
                "font-[700] text-base md:text-[21px] font-lato text-grey_700"
              }
            >
              Or send us a DM directly
            </h6>

            <div className={"flex gap-1 md:gap-x-2 mt-2 mb-2 md:mb-0"}>
              {socials?.map(({ icon, link, name }) => (
                <SocialBtn icon={icon} name={name} link={link} />
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;

const InputItem = ({ label, placeHolder, ...rest }) => (
  <div className={" md:mr-0 flex-1 "}>
    <label className={"text-sm font-inter font-[500] text-grey_700 "} for="">
      {label}
    </label>
    <br />
    <input
      className={
        "bg-white rounded-[8px] border border-grey_300 mt-2 w-full px-4 py-2.5 font-inter text-sm md:text-base"
      }
      placeholder={placeHolder}
      {...rest}
    />
  </div>
);

const SocialBtn = ({ icon, name, link }) => {
  const [state, setState] = useState("hidden");

  return (
    <div
      className={"w-10 md:w-12"}
      onMouseEnter={() => {
        setState("block  ");
      }}
      onMouseLeave={() => {
        setState("hidden ");
      }}
    >
      <a href={link}>
        <div
          className={
            "rounded-lg h-8 w-8 md:h-10 md:w-10 p-1 flex flex-col justify-center items-center border-2 border-[#272D2D] hover:bg-primary_300"
          }
        >
          {icon}
        </div>
      </a>
      <h6
        className={`font-lato text-[10px] text-[#272D2D] font-[700] mt-1 text-center ${state}`}
      >
        {name}
      </h6>
    </div>
  );
};

const socials = [
  {
    icon: <FacebookSvg />,
    name: "Facebook",
    link: "https://web.facebook.com/pickupmtaani/?_rdc=1&_rdr",
  },
  {
    icon: <InstagramSvg />,
    name: "Instagram",
    link: "https://www.instagram.com/pickup_mtaani_cs/",
  },
  {
    icon: <ThreadsSvg />,
    name: "Threads",
    link: "https://www.threads.net/@pickup_mtaani_cs",
  },
  {
    icon: <TwitterSvg />,
    name: "X (twitter)",
    link: "https://twitter.com/PMtaani?t=5sRBiLkDpW3zhm0Kxy20-w&s=31",
  },
  {
    icon: <MailSvg />,
    name: "Email",
    link: "mailto:pickupmtaani@gmail.com",
  },
  {
    icon: <MessageSvg />,
    name: "SMS",
    link: "sms:+254110151006",
  },
  {
    icon: <PhoneSvg />,
    name: "Call",
    link: "tel:+254110151006",
  },
];

const Loader = () => (
  <div className={"flex justify-center items-center"} role="status">
    <svg
      aria-hidden="true"
      className="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-500 fill-yellow-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>

    <span className="font-semibold text-yellow-500">Sending...</span>
  </div>
);
