import React, { useContext, useState } from "react";
import Map from "../components/map";
import { useLoadScript } from "@react-google-maps/api";
import FloatingCards from "../components/tracker/FloatingCards";
import OrderDetails from "../components/tracker/order/OrderDetails";
import ReceiptDetails from "../components/tracker/package_details/ReceiptDetails";
import JourneyPane from "../components/tracker/journey/JourneyPane";
import { Context } from "../context/StorageContext";

const Tracker = () => {
  const [showReceipt, setShowReceipt] = useState(false);
  const [showJourney, setShowJourney] = useState(false);
  const { trackingData } = useContext(Context);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDctWcs7BwfAxWypCIhI2tnEOyIi5FCqLk",
  });

  const handleReceiptClick = () => {
    setShowReceipt((prev) => !prev);
    setShowJourney(false);
  };
  const handleJourneyClick = () => {
    setShowJourney((prev) => !prev);
    setShowReceipt(false);
  };

  return (
    <div
      className={
        " bg-slate-100 flex flex-col gap-5 min-h-[calc(100vh-96px)] relative h-screen"
      }
    >
      {trackingData?.status === "collected" && (
        <div className={"absolute inset-0 bg-gray-900 opacity-95 z-20"} />
      )}
      {/*  Tracking title */}
      {!isLoaded ? (
        <div className={"flex justify-center items-center bg-white"}>
          <span className="text-3xl font-semibold text-gray-70">
            Loading . . .
          </span>
        </div>
      ) : (
        <Map />
      )}

      <FloatingCards />

      <OrderDetails
        showJourney={showJourney}
        showReceipt={showReceipt}
        handleReceiptClick={handleReceiptClick}
        handleViewJourney={handleJourneyClick}
      />

      <ReceiptDetails
        handleClose={handleReceiptClick}
        isOpen={showReceipt && !showJourney}
      />

      <JourneyPane
        isOpen={showJourney && !showReceipt}
        handleClose={handleJourneyClick}
      />
    </div>
  );
};

export default Tracker;

/**
 * @typedef {{
 * category:boolean,
 * location:Location,
 * name:string,
 * status:string,
 * locationName:string
 * }} DeliveryPackage
 */

/**
 * @typedef {{
 * lng:float,
 * lat:float,
 * }} Location
 */

let lastStates = ["delivered", "collected"];
