import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Footer,
} from "../components/dashboard";
import Spinner from "../components/general/Spinner";
import AboutAgents from "../services/AboutAgents";
import { toast } from "react-toastify";

const Agents = () => {
	const [loading, setLoading] = useState(false);
	const [agent_locations, setAgentLocations] = useState(null);
	const [filtered_sections, setFilteredSections] = useState([]);

	function getSectionAgents() {
		setLoading(true);
		var keyedLocations;

		AboutAgents.getAgentsZones().then(res => {
			const locs = res;
			const keyed_locs = {};
			locs?.forEach(loc => {
				keyed_locs[loc?.id] = loc?.name;
			});
			keyedLocations = keyed_locs;
		}).catch(error => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});


		AboutAgents.getAgentsGrouped().then(res => {
			const GROUPED_AGENTS = res;
			let new_list = {};
			let shelf_list = [];
			let sectioned_list = [];

			for (let i = 0; i <= GROUPED_AGENTS?.length; i++) {
				const current_agent = GROUPED_AGENTS[i];
				const shops = current_agent?.data;

				if (current_agent && current_agent.location_id_id) {
					new_list[current_agent.location_id_id] = current_agent.data;

					sectioned_list.push({
						key: current_agent.location_id_id,
						data: current_agent.data,
					});

					shops.forEach(shop => {
						if (shop?.hasShelf) {
							shelf_list.push(shop);
						}
					});
				}

				//
			}

			const sectionedAgents = sectioned_list;

			let newSectionedList = [];
			sectionedAgents.forEach((item) => {
				if (item?.data.length) {
					if (keyedLocations[item?.key]) {
						newSectionedList.push({ ...item, name: keyedLocations[item?.key] });
					}
				}
			});

			const sortedList = newSectionedList.sort((a, b) => a?.name?.localeCompare(b?.name));
			setFilteredSections(sortedList);
			setAgentLocations(sortedList);


			var start = 0;
			var locations = [];

			while (start < sortedList.length) {
				locations.push(sortedList[start]['name']);
				start = start + 1;
			}

			setLoading(false);

		}).catch(error => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});
	}

	useEffect(() => {
		getSectionAgents();
	}, []);

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className="main flex-grow ">
				{
					loading ?
						<Spinner />
						:
						<div>
							{
								agent_locations ?
									<div>
										<h5
											className={
												"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
											}
										>
											Pickup Mtaani Agent List
										</h5>

										{
											agent_locations.map(
												result =>
												<div className="lg:flex lg:justify-center">
												<details class="mb-2 lg:w-1/2 mx-4">
														<summary class="bg-gray-200 p-4 rounded-lg cursor-pointer shadow-md mb-4">
															<span class="font-semibold">{result.name}</span>
														</summary>
														{
															result.data.map(result2 =>
																<ol class="ml-8 list-disc">
																	<li className='mb-3 mt-3 '>
																		<Link to={"/agent/".concat(result2.id)}>
																			<span class="font-semibold text-green-500">{result2.business_name}</span>
																		</Link>
																	</li>
																</ol>
															)
														}
												</details>
												</div>
											)
										}
									</div>
									:
									<div>

									</div>
							}
						</div>
				}
			</div>
			<Footer />
		</div>
	);
};

export default Agents;
