import React, { useContext } from "react";
import MinusSvg from "../../assets/svg/MinusSvg";
import PlusSvg from "../../assets/svg/PlusSvg";
import { Context } from "../../context/StorageContext";

const ZoomCard = () => {
  const { setZoom } = useContext(Context);
  return (
    <div
      className={"p-1 rounded-xl flex flex-col gap-y-2  shadow-lg bg-gray-50"}
    >
      {/*  + button  */}
      <ZoomBtn onClick={() => setZoom((prev) => prev + 1)}>
        <PlusSvg />
      </ZoomBtn>
      {/*    - Button   */}
      <ZoomBtn onClick={() => setZoom((prev) => prev - 1)}>
        <MinusSvg />
      </ZoomBtn>
    </div>
  );
};

export default ZoomCard;

const ZoomBtn = ({ children, onClick }) => (
  <button
    className={
      "h-14 w-14 bg-white rounded-xl shadow flex items-center justify-center hover:bg-gray-50"
    }
    onClick={onClick}
  >
    {children}
  </button>
);
