import React, { useState } from "react";
import { MapPinSvg, SearchSVG } from "../../../assets/svg";
import { COLORS } from "../../../assets/Theme";
import { useNavigate } from "react-router-dom";

const SearchInput = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!id) {
      return;
    }
    navigate("/track-package?" + id);
  };
  return (
    <form
      className={" sticky top-1 left-1 flex z-20 justify-center"}
      onSubmit={handleSubmit}
    >
      <div
        className={
          "bg-white z-30 shadow h-[42px] flex items-center pl-2 rounded-xl overflow-hidden lg:w-12/12 w-full"
        }
      >
        {/*  icon  */}
        <MapPinSvg />

        <div className={"h-4 rounded-full bg-primary_300 w-0.5 mx-2"} />

        <input
          className={" h-full focus:outline-none flex-1 text-[13px]"}
          placeholder={"Enter tracking code"}
          value={id}
          onChange={(e) => setId(e.target.value)}
        />

        <button
          className={
            "gap-2 flex items-center justify-center text-primary_300 px-3 h-full font-[600] hover:bg-zinc-100"
          }
          type={"submit"}
        >
          <SearchSVG color={COLORS.grey_600} />
        </button>
      </div>
    </form>
  );
};

export default SearchInput;
