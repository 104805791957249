/**
 * @link https://pickupmtaani.atlassian.net/browse/DEV-279
 * @since X.X.X
 *
 * @param {String} state
 * @returns String
 * @desc gets the current stage(agent, warehouse, rider) of a package for tracking purposes
 */
function getCurrentStage(state) {
  switch (state) {
    case "assigned":
      return "agent";

    case "pending-stock-agent":
      return "agent";
    case "pending-shelf-agent":
      return "agent";
    case "pending-stock-doorstep":
      return "agent";
    case "pending-shelf-doorstep":
      return "agent";
    case "pending-stock-errand":
      return "agent";
    case "pending-shelf-errand":
      return "agent";
    case "on-transit":
      return "rider";
    case "dropped":
      return "warehouse";
    case "recieved-warehouse":
      return "warehouse";
    case "assigned-warehouse":
      return "warehouse";
    case "warehouse-transit":
      return "rider";
    case "delivered":
      return "agent";
    case "collected":
      return "agent";
    default:
      break;
  }
}

const WAREHOUSE = {
  id: 3,
  agent_description: "opposite Afya center ",
  business_name: "Philadelphia house",
  agent_locations: {
    id: 168,
    name: "CBD - TOWN NAIROBI",
  },
};
const getCurrentAgent = (state, pkg) => {
  switch (state) {
    case "assigned":
      return (
        pkg?.package
          ?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details ||
        pkg?.package?.agents_details
      );
    case "on-transit":
      return null;
    case "dropped":
      return WAREHOUSE;
    case "recieved-warehouse":
      return WAREHOUSE;
    case "assigned-warehouse":
      return WAREHOUSE;
    case "warehouse-transit":
      return null;
    case "delivered":
      if (pkg?.package?.type === "agent") {
        return pkg?.package
          ?.agents_details_agent_agent_sent_packages_receieverAgentID_idToagents_details;
      }
      return null;
    case "collected":
      return "agent";
    default:
      break;
  }
};

function getCurrentStateDetails(state, rsn) {
  switch (state) {
    case "rejected":
      return {
        stage: 1,
        title: "Package Rejected",
        desc: "Package Rejected due to, " + rsn || "",
      };
    case "assigned":
      return {
        stage: 1,
        title: "Awaiting delivery",
        desc: "Package is Awaiting Delivery to warehouse for sorting.",
      };

    case "picked-from-seller":
      return {
        stage: 1,
        title: "Awaiting Collection",
        desc: "Package is awaiting pickup by customer.",
      };
    case "early_collection":
      return {
        stage: 1,
        title: "Awaiting Collection",
        desc: "Package is awaiting pickup by customer.",
      };
    case "request":
      return {
        stage: 0,
        title: "Package Submitted",
        desc: "Package Submitted and is awaiting drop-off to agent.",
      };
    case "pending-stock-agent":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "pending-shelf-agent":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "pending-stock-doorstep":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "pending-shelf-doorstep":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "pending-stock-errand":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "pending-shelf-errand":
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case "on-transit":
      return {
        stage: 2,
        title: "On The Way",
        desc: "Package is being delivered to warehouse for sorting",
      };
    case "dropped":
      return {
        stage: 3,
        title: "Sorting (Philadelphia House)",
        desc: "Package is at philadelphia house(next to Afya center) for sorting",
      };
    case "recieved-warehouse":
      return {
        stage: 3,
        title: "Sorting (Philadelphia House)",
        desc: "Package is at philadelphia house(next to Afya center) for sorting",
      };
    case "assigned-warehouse":
      return {
        stage: 3,
        title: "Sorting (Philadelphia House)",
        desc: "Package is at philadelphia house(next to Afya center) for sorting",
      };
    case "warehouse-transit":
      return {
        stage: 4,
        title: "On The Way",
        desc: "Package is being delivered to destination.",
      };

    case "delivered":
      return {
        stage: 5,
        title: "Delivered",
        desc: "Package has been delivered to destination and is awaiting collection.",
      };

    case "collected":
      return {
        stage: 6,
        title: "Collected",
        desc: "Package collected by customer",
      };

    default:
      break;
  }
}

const Helper = {
  getCurrentStage,
  getCurrentAgent,
  getCurrentStateDetails,
};

export default Helper;
