import axios from "axios";
import { BASE_URL } from "../Config";

const trackPackage = async (pkg_id) => {
  const response = await axios.get(`${BASE_URL}/track/${pkg_id}`);

  return response?.data;
};

const getPackageNarrations = async (pkg_id) => {
  // /agent-package-narations/635cd134d8a53641d19a378c
  // const response = await axios.get(`/agent/track/packages/${pkg_id}`);
  const response = await axios.get(
    `${BASE_URL}/agent/track/packages/${pkg_id}`
  );

  return response?.data;
};

const getDoorstepPackageNarrations = async (pkg_id) => {
  // /agent-package-narations/635cd134d8a53641d19a378c
  const response = await axios.get(
    `${BASE_URL}/door-step/track/packages/${pkg_id}`
  );

  return response?.data;
};

const getShelfPackageNarrations = async (pkg_id) => {
  // /agent-package-narations/635cd134d8a53641d19a378c
  const response = await axios.get(
    `${BASE_URL}/rent-shelf/track/packages/${pkg_id}`
  );

  return response?.data;
};

const getErrandPackageNarrations = async (pkg_id) => {
  // /agent-package-narations/635cd134d8a53641d19a378c
  const response = await axios.get(
    `${BASE_URL}/errand/track/packages/${pkg_id}`
  );

  return response?.data;
};

// fetch all locations
const fetchAllLocations = async () => {
  const response = await axios.get(`${BASE_URL}/agents-zones-abc`);

  return response?.data;
};

// fetch specific location agents
const fetchLocationAgents = async (id) => {
  const response = await axios.get(`${BASE_URL}/agents/location/${id}`);

  return response?.data;
};

// fetch singleAgent
const fetchAgent = async (id) => {
  const response = await axios.get(`${BASE_URL}/agent/${id}`);

  return response?.data;
};

const fetchPackage = async (id, type) => {
  const response = await axios.get(`${BASE_URL}/package/${id}?type=${type}`);

  return response?.data;
};

const TrackingServices = {
  getPackageNarrations,
  getDoorstepPackageNarrations,
  getShelfPackageNarrations,
  getErrandPackageNarrations,
  fetchAllLocations,
  fetchLocationAgents,
  fetchAgent,
  fetchPackage,
  trackPackage,
};
export default TrackingServices;
