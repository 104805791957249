import axios from "axios";
import {BASE_URL} from "../Config";

/**
 *
 * @param {{name:string, phone:string, message:string, email:string}} body
 * @returns {Promise<*>}
 * @desc sends email
 */
const sendEmail = async (body) => {
  const response = await axios.post(`${BASE_URL}/contactus`, body);

  return response?.data;
};

const ContactServices = { sendEmail };

export default ContactServices;
