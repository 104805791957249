import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from "react";
import {GoogleMap, InfoWindowF, MarkerF} from "@react-google-maps/api";
import {useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {toast} from "react-toastify";

import Helper from "../../utils/Helper";
import TrackingServices from "../../services/TrackingServices";
import {Context} from "../../context/StorageContext";

/**
 * @since X.X.X
 * @Link https://pickupmtaani.atlassian.net/jira/software/c/projects/DEV/issues/DEV-279
 *
 * @param {DeliveryPackage} pkg
 * @return {JSX.Element}
 * @desc renders tracker map
 */
const Map = ({ pkgDetails }) => {
  const { search } = useLocation();
  const trackingId = search.split("?")?.at(1);
  const { setTrackingData, zoom } = useContext(Context);

  const { data } = useQuery({
    queryKey: ["todos", trackingId],
    queryFn: () => TrackingServices.trackPackage(trackingId),
    onSuccess: (res) => {
      setTrackingData(res.packageDetails);
    },
    onError: (err) => {
      toast.error(
        "Tracking error. " + err.response.data?.message || err.message
      );
    },
    refetchInterval: 500000,
  });

  const pkg = data?.packageDetails;
  const mapRef = useRef();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const currentAgent = useMemo(() => {
    return Helper.getCurrentAgent(pkg?.status, pkgDetails);
  }, [pkg?.status, pkgDetails]);

  // gets package tracking state(agent/rider/warehouse)
  const packageState = useMemo(
    () => Helper.getCurrentStage(pkg?.status),
    [pkg?.status]
  );

  const center = useMemo(
    () => ({
      lat: -1.2878412,
      lng: 36.8278173,
    }),
    []
  );
  const options = useMemo(
    () => ({
      //   mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
      // mapId: "f2dbb0573bbff1b1",
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const panToPoint = useCallback((lat, lng) => {
    mapRef.current?.panTo({
      lat: Number(lat),
      lng: Number(lng),
    });
    mapRef?.current?.setZoom(17);
    // mapRef.current.duration(1000);
  }, []);

  useEffect(() => {
    const currentCoords = data?.packageDetails?.location;

    if (currentCoords?.lat) {
      panToPoint(currentCoords?.lat, currentCoords?.lng);
    }
  }, [data]);
  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      mapContainerClassName="map-container h-[43vh] md:h-screen "
      options={options}
      onLoad={onLoad}
      mapContainerStyle={{
        width: "100%",
      }}
      heading={parseInt(pkg?.location?.heading) || 0}
    >
      {/*{Object.values(coordinates)?.map((c) => (*/}
      {packageState ? (
        <MarkerF
          onClick={() => setShow(true)}
          position={{
            lat: Number(pkg?.location?.lat),
            lng: Number(pkg?.location?.lng),
          }}
          icon={{
            url:
              packageState === "rider"
                ? "/images/RIDER.png"
                : packageState === "warehouse"
                ? "/images/marker.png"
                : packageState.trim() === "agent"
                ? "/images/marker.png"
                : "/images/marker.png",
            scaledSize: new window.google.maps.Size(
              packageState === "rider" ? 80 : 50,
              packageState === "rider" ? 40 : 65
            ),
          }}
        />
      ) : (
        pkg?.category === "shelf" && (
          <MarkerF
            onClick={() => setShow(true)}
            position={{
              lat: pkg?.location?.lat,
              lng: pkg?.location?.lng,
            }}
            icon={{
              url: "/images/marker.png",
              scaledSize: new window.google.maps.Size(
                packageState === "rider" ? 80 : 50,
                packageState === "rider" ? 40 : 65
              ),
            }}
          />
        )
      )}
      {/*))}*/}

      {show && currentAgent && (
        <InfoWindowF
          onCloseClick={() => {
            setShow(false);
          }}
          options={{
            pixelOffset: new window.google.maps.Size(0, -40),
          }}
          position={{
            lat: Number(pkg?.location?.lat),
            lng: Number(pkg?.location?.lng),
          }}
        >
          <div
            style={{
              opacity: 0.75,
              padding: 3,
            }}
          >
            <div>
              <span className="font-bold">{currentAgent?.business_name}</span>

              <h6 className=" mt-2">{currentAgent?.agent_locations?.name}</h6>
              <div className={"mt-2"}>
                <button
                  onClick={() => navigate(`/agent/${currentAgent?.id}`)}
                  className="py-1 px-2 justify-center bg-slate-500 text-white rounded-sm hover:scale-[0.995] hover:text-xs hover:font-semibold duration-300"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </InfoWindowF>
      )}
    </GoogleMap>
  );
};

export default Map;
