import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard, Tracker } from "./pages";
import Terms from "./pages/Terms";
import Navbar from "./components/general/Navbar";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Agents from "./pages/Agents";
import Agent from "./pages/Agent";
import SideNav from "./components/general/SideNav";
import WalletReset from "./pages/WalletReset.js";
import { QueryClient, QueryClientProvider } from "react-query";
import StorageContext from "./context/StorageContext";
import RentShelfPolicy from './pages/PolicyRentShelf.js';
import RiderPrivacyPolicy from './pages/PolicyRider.js';
import CollectCashPayment from "./pages/CollectCashPayment";
import VendorPrivacyPolicy from "./pages/PolicyVendor";
import ShelfManagerPay from "./pages/ShelfManagerPay";

function App() {
	const [openSideBar, setOpenSideBar] = useState(false);

	const queryClient = new QueryClient();

	return (
		<StorageContext>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<div className="App min-h-[100vh] bg-white_bg overflow-x-hidden">
						<Navbar openSideNav={() => setOpenSideBar(true)} />
						<SideNav
							isOpen={openSideBar}
							handleCloseNav={() => setOpenSideBar(false)}
						/>
						<Routes>
							<Route path={"/"} element={<Dashboard />} />
							<Route path={"/track-package"} element={<Tracker />} />
							<Route path={"/terms"} element={<Terms />} />
							<Route path={"/wallet-reset"} element={<WalletReset />} />
							<Route path={"/agents"} element={<Agents />} />
							<Route path={"/agent/:id"} element={<Agent />} />
							<Route path={"/privacy-policy/rent-shelf-manager"} element={<RentShelfPolicy />} />
							<Route path={"/privacy-policy/rider"} element={<RiderPrivacyPolicy />} />
							<Route path={"/privacy-policy/vendor"} element={<VendorPrivacyPolicy />} />
							<Route path={"/payment/:type/:receipt"} element={<CollectCashPayment />} />
							<Route path={"/payment-shelf/:shelfid/"} element={<ShelfManagerPay />} />
						</Routes>
						<ToastContainer />
					</div>
				</BrowserRouter>
			</QueryClientProvider>
		</StorageContext>
	);
}

export default App;
